import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import "../assets/vendor/materializeicon/material-icons.css";
import "../assets/vendor/bootstrap-4.3.1/css/bootstrap.min.css";
import "../assets/vendor/swiper/css/swiper.min.css";
import "../assets/css/style.css";

// import "../assets/js/jquery-3.3.1.min.js";
// import "../assets/js/popper.min.js";
import "../assets/vendor/bootstrap-4.3.1/js/bootstrap.min.js";
// import "bootstrap";
import $ from "jquery"; //import jQuery
// import "../assets/vendor/swiper/js/swiper.min.js";

// import swipper = url('cdn.shopify.com/s/files/1/0659/4529/t/75/assets/swiper.min.js?10728068994914913511');
// import "../assets/js/main.js";

import loading from "vuejs-loading-screen";

Vue.use(loading, {
  bg: "rgba(119, 76, 184, 0.8)",
  slot: `
    <div class="px-5 py-3 bg-gray-800 rounded">
      <h3 class="text-3xl text-white"><i class="fas fa-spinner fa-pulse"></i> LOADING...</h3>
    </div>
  `,
});

// import SwiperClass from "vue-swiper";

// Vue.use(SwiperClass);

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/berita/:id_berita/show",
    name: "berita-show",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BeritaShowView.vue"),
  },
  {
    path: "/berita",
    name: "berita",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BeritaView.vue"),
  },
  {
    path: "/bacaan",
    name: "bacaan",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BacaanView.vue"),
  },
  {
    path: "/bacaan/:id_bacaan/show",
    name: "bacaan-show",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BacaanShowView.vue"),
  },
  {
    path: "/pendeta",
    name: "pendeta",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PendetaView.vue"),
  },
  {
    path: "/jemaat",
    name: "jemaat",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/JemaatView.vue"),
  },
  {
    path: "/event",
    name: "event",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EventView.vue"),
  },
  {
    path: "/produk",
    name: "produk",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProdukView.vue"),
  },
  {
    path: "/produk-list",
    name: "produk-list",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProdukListView.vue"),
  },
  {
    path: "/produk/:id_produk/show",
    name: "produk-show",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProdukShowView.vue"),
  },
  {
    path: "/cart/:id_produk/show",
    name: "cart",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CartView.vue"),
  },
  {
    path: "/pemberitahuan",
    name: "pemberitahuan",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PembeitahuanView.vue"),
  },
  {
    path: "/tokoanda",
    name: "tokoanda",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TokoAndaView.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProfileView.vue"),
  },
  {
    path: "/profile/:id_profile/edit",
    name: "profile-edit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EditProfileView.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FaqView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
