<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <html lang="en" class="pink-theme">
      <head>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover, user-scalable=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="Maxartkiller" />

        <title>.:: GKJ SARI MULYO - YOGYAKARTA::.</title>
      </head>
      <body>
        <router-view />
      </body>
    </html>
  </div>
</template>
<script>
// import $ from "jquery"; //import jQuery
// import SwiperClass, { Pagination } from "Swiper";
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";

// import swiper module styles
// import "swiper/css";
// import "swiper/css/pagination";
// more module style...

export default {
  components: {
    // SwiperClass,
  },
  data() {
    return {};
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&amp;display=swap");
</style>
