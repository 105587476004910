<template>
  <div class="home">
    <div class="row no-gutters vh-100 loader-screen">
      <div class="col align-self-center text-white text-center">
        <img src="../assets/img/logo.png" alt="logo" />
        <h1><span class="font-weight-light">GKJ</span>SARIMULYO</h1>
        <div class="laoderhorizontal">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="sidebar">
      <div class="text-center">
        <div class="figure-menu shadow">
          <figure><img src="../assets/img/user1.png" alt="" /></figure>
        </div>
        <h5 class="mb-1">Yosef Murya</h5>
        <p class="text-mute small">Sleman, Yogyakarta</p>
      </div>
      <br />
      <div class="row mx-0">
        <div class="col">
          <div class="card mb-3 border-0 shadow-sm bg-template-light">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <p class="text-secondary small mb-0">Total Pembelian</p>
                  <h6 class="text-dark my-0">Rp.20.585</h6>
                </div>
                <div class="col-auto">
                  <button class="btn btn-default button-rounded-36 shadow">
                    <i class="material-icons">add</i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <h5 class="subtitle text-uppercase"><span>Menu</span></h5>
          <div class="list-group main-menu">
            <router-link
              to="/tokoanda"
              class="list-group-item list-group-item-action menu-btn"
              >Toko Anda</router-link
            >
            <router-link
              to="/pemberitahuan"
              class="list-group-item list-group-item-action menu-btn"
              >Pemberitahuan
              <span class="badge badge-dark text-white">6</span></router-link
            >
            <router-link
              to="/produk"
              class="list-group-item list-group-item-action menu-btn"
              >Semua Produk</router-link
            >
            <router-link
              :to="{
                name: 'cart',
                params: { id_produk: 1 },
              }"
              class="list-group-item list-group-item-action menu-btn"
              >Order Saya</router-link
            >
            <router-link
              to="/profile"
              class="list-group-item list-group-item-action menu-btn"
              >Profile Saya</router-link
            >
            <router-link
              to=""
              class="list-group-item list-group-item-action menu-btn"
              >FAQs</router-link
            >
            <router-link
              to=""
              class="list-group-item list-group-item-action menu-btn"
              >Login</router-link
            >
            <router-link
              to=""
              class="list-group-item list-group-item-action menu-btn"
              >Logout</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="header">
        <div class="row no-gutters">
          <div class="col-auto">
            <button class="btn btn-link text-dark menu-btn">
              <img src="../assets/img/menu.png" alt="" /><span
                class="new-notification"
              ></span>
            </button>
          </div>
          <div class="col text-center">
            <img
              src="../assets/img/logo-header.png"
              alt=""
              class="header-logo"
            />
          </div>
          <div class="col-auto">
            <router-link to="/profile" class="btn btn-link text-dark"
              ><i class="material-icons">account_circle</i></router-link
            >
          </div>
        </div>
      </div>
      <div class="container">
        <!-- <input
          type="text"
          class="form-control form-control-lg search my-3"
          placeholder="Search"
        /> -->
        <h6 class="subtitle">Berita</h6>
        <!-- <div class="row"> -->
        <div class="swiper-container news-slide">
          <swiper
            class="swiper-wrapper"
            :slides-per-view="1"
            :space-between="20"
            :loop="false"
            :pagination="false"
            :navigation="false"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <!-- <div class="swiper-wrapper"> -->
            <swiper-slide class="swiper-slide">
              <div class="card shadow-sm border-0 bg-dark text-white">
                <figure class="background">
                  <img src="../assets/img/bacaan-injil.jpg" alt="" />
                </figure>
                <div class="card-body">
                  <router-link
                    :to="{
                      name: 'berita-show',
                      params: { id_berita: 1 },
                    }"
                    class="
                      btn btn-default
                      button-rounded-36
                      shadow-sm
                      float-bottom-right
                    "
                    ><i class="material-icons md-18"
                      >arrow_forward</i
                    ></router-link
                  >
                  <h5 class="small">
                    Elegi untuk Bumi - Si lintah mempunyai dua anak perempuan:
                    "Untukku!" dan "Untukku!" (Amsal 30:15a)
                  </h5>
                  <p class="text-mute small">Oleh: Pdt. Fendi Susanto, S.Si</p>
                </div>
              </div></swiper-slide
            >
            <swiper-slide class="swiper-slide">
              <div class="card shadow-sm border-0 bg-dark text-white">
                <figure class="background">
                  <img src="../assets/img/jesus-salib.jpg" alt="" />
                </figure>
                <div class="card-body">
                  <router-link
                    :to="{
                      name: 'berita-show',
                      params: { id_berita: 1 },
                    }"
                    class="
                      btn btn-default
                      button-rounded-36
                      shadow-sm
                      float-bottom-right
                    "
                    ><i class="material-icons md-18"
                      >arrow_forward</i
                    ></router-link
                  >
                  <h5 class="small">Kagum dengan Tuhan</h5>
                  <p class="text-mute small">
                    Oleh : Pdt. Christian Muryati, S.Pd.K.
                  </p>
                </div>
              </div></swiper-slide
            >
            <swiper-slide class="swiper-slide">
              <div class="card shadow-sm border-0 bg-dark text-white">
                <figure class="background">
                  <img src="../assets/img/jesus-surf.jpg" alt="" />
                </figure>
                <div class="card-body">
                  <router-link
                    :to="{
                      name: 'berita-show',
                      params: { id_berita: 1 },
                    }"
                    class="
                      btn btn-default
                      button-rounded-36
                      shadow-sm
                      float-bottom-right
                    "
                    ><i class="material-icons md-18"
                      >arrow_forward</i
                    ></router-link
                  >
                  <h5 class="small">Peselancar Satu Lengan</h5>
                  <p class="text-mute small">
                    Oleh : Pdt. Christian Muryati, S.Pd.K.
                  </p>
                </div>
              </div></swiper-slide
            >
            <!-- </div> -->
          </swiper>
        </div>
        <!-- Swiper -->
        <!-- </div> -->
        <div class="container mb-3">
          <div class="row">
            <!-- <div class="col text-center">
              <h5 class="subtitle mb-1">Most Exciting Feature</h5>
              <p class="text-secondary">Take a look at our services</p>
            </div> -->
          </div>
          <div class="row text-center mt-4">
            <!-- <router-link to="/berita"> -->
            <router-link to="/berita" class="col-6 col-md-3">
              <div class="card shadow-sm border-0 mb-4">
                <div class="card-body">
                  <i class="material-icons mb-4 md-36 text-template"
                    >library_books</i
                  >
                  <!-- <h2>2546</h2> -->
                  <p class="text-secondary text-mute">Berita</p>
                </div>
              </div>
            </router-link>
            <!-- </router-link> -->
            <router-link to="/produk" class="col-6 col-md-3">
              <div class="card shadow-sm border-0 mb-4">
                <div class="card-body">
                  <i class="material-icons mb-4 md-36 text-template"
                    >local_mall</i
                  >
                  <!-- <h2>635</h2> -->
                  <p class="text-secondary text-mute">Produk</p>
                </div>
              </div>
            </router-link>
            <router-link to="/bacaan" class="col-6 col-md-3">
              <div class="card shadow-sm border-0 mb-4">
                <div class="card-body">
                  <i class="material-icons mb-4 md-36 text-template"
                    >collections_bookmark</i
                  >
                  <!-- <h2>1542</h2> -->
                  <p class="text-secondary text-mute">Bacaan</p>
                </div>
              </div>
            </router-link>
            <router-link to="/pendeta" class="col-6 col-md-3">
              <div class="card shadow-sm border-0 mb-4">
                <div class="card-body">
                  <i class="material-icons mb-4 md-36 text-template"
                    >person_pin</i
                  >
                  <!-- <h2>154</h2> -->
                  <p class="text-secondary text-mute">Pendeta</p>
                </div>
              </div>
            </router-link>
            <router-link to="/event" class="col-6 col-md-3">
              <div class="card shadow-sm border-0 mb-4">
                <div class="card-body">
                  <i class="material-icons mb-4 md-36 text-template"
                    >date_range</i
                  >
                  <!-- <h2>154</h2> -->
                  <p class="text-secondary text-mute">Event</p>
                </div>
              </div>
            </router-link>
            <router-link to="/jemaat" class="col-6 col-md-3">
              <div class="card shadow-sm border-0 mb-4">
                <div class="card-body">
                  <i class="material-icons mb-4 md-36 text-template"
                    >folder_shared</i
                  >
                  <!-- <h2>154</h2> -->
                  <p class="text-secondary text-mute">Jemaat</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <h6 class="subtitle">Kategori Produk</h6>
        <div class="row">
          <!-- Swiper -->
          <div class="swiper-container small-slide">
            <!-- <div class="swiper-wrapper"> -->
            <swiper
              class="swiper-wrapper"
              :slides-per-view="2"
              :space-between="2"
              :loop="true"
              :pagination="false"
              :navigation="false"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
            >
              <!-- <div class="swiper-slide"> -->
              <swiper-slide class="swiper-slide">
                <div class="card shadow-sm border-0">
                  <div class="card-body">
                    <div class="row no-gutters h-100">
                      <img
                        src="../assets/img/orange.png"
                        alt=""
                        class="small-slide-right"
                      />
                      <div class="col-8">
                        <button class="btn btn-sm btn-link p-0">
                          <i class="material-icons md-18">favorite_outline</i>
                        </button>
                        <a
                          href="all-products.html"
                          class="text-dark mb-1 mt-2 h6 d-block"
                          >Citrus
                        </a>
                        <p class="text-secondary small">
                          Oranges, Grapefruit, Mandarins
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </swiper-slide>
              <!-- <div class="swiper-slide"> -->
              <swiper-slide class="swiper-slide">
                <div class="card shadow-sm border-0">
                  <div class="card-body">
                    <div class="row no-gutters h-100">
                      <img
                        src="../assets/img/banana-small.png"
                        alt=""
                        class="small-slide-right"
                      />
                      <div class="col-8">
                        <button class="btn btn-sm btn-link p-0">
                          <i class="material-icons md-18">favorite</i>
                        </button>
                        <a
                          href="all-products.html"
                          class="text-dark mb-1 mt-2 h6 d-block"
                          >Tropical
                        </a>
                        <p class="text-secondary small">Bananas, Mangoes</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </swiper-slide>
              <swiper-slide class="swiper-slide">
                <!-- <div class="swiper-slide"> -->
                <div class="card shadow-sm border-0">
                  <div class="card-body">
                    <div class="row no-gutters h-100">
                      <img
                        src="../assets/img/grapes.png"
                        alt=""
                        class="small-slide-right"
                      />
                      <div class="col-9">
                        <button class="btn btn-sm btn-link p-0">
                          <i class="material-icons md-18">favorite_outline</i>
                        </button>
                        <a
                          href="all-products.html"
                          class="text-dark mb-1 mt-2 h6 d-block"
                          >Berries</a
                        >
                        <p class="text-secondary small">
                          Strawberries, Raspberries, Blueberries
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </swiper-slide>
              <!-- <div class="swiper-slide"> -->
              <swiper-slide class="swiper-slide">
                <div class="card shadow-sm border-0">
                  <div class="card-body">
                    <div class="row no-gutters h-100">
                      <img
                        src="../assets/img/orange.png"
                        alt=""
                        class="small-slide-right"
                      />
                      <div class="col-8">
                        <button class="btn btn-sm btn-link p-0">
                          <i class="material-icons md-18">favorite_outline</i>
                        </button>
                        <a
                          href="all-products.html"
                          class="text-dark mb-1 mt-2 h6 d-block"
                          >Citrus
                        </a>
                        <p class="text-secondary small">
                          Oranges, Grapefruit, Mandarins
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </swiper-slide>
              <!-- <div class="swiper-slide"> -->
              <swiper-slide class="swiper-slide">
                <div class="card shadow-sm border-0">
                  <div class="card-body">
                    <div class="row no-gutters h-100">
                      <img
                        src="../assets/img/banana-small.png"
                        alt=""
                        class="small-slide-right"
                      />
                      <div class="col-8">
                        <button class="btn btn-sm btn-link p-0">
                          <i class="material-icons md-18">favorite</i>
                        </button>
                        <a
                          href="all-products.html"
                          class="text-dark mb-1 mt-2 h6 d-block"
                          >Tropical
                        </a>
                        <p class="text-secondary small">Bananas, Mangoes</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </swiper-slide>
              <!-- <div class="swiper-slide"> -->
              <swiper-slide class="swiper-slide">
                <div class="card shadow-sm border-0">
                  <div class="card-body">
                    <div class="row no-gutters h-100">
                      <img
                        src="../assets/img/grapes.png"
                        alt=""
                        class="small-slide-right"
                      />
                      <div class="col-9">
                        <button class="btn btn-sm btn-link p-0">
                          <i class="material-icons md-18">favorite_outline</i>
                        </button>
                        <a
                          href="all-products.html"
                          class="text-dark mb-1 mt-2 h6 d-block"
                          >Berries</a
                        >
                        <p class="text-secondary small">
                          Strawberries, Raspberries, Blueberries
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </swiper-slide>
              <swiper-slide class="swiper-slide">
                <!-- <div class="swiper-slide"> -->
                <div class="card shadow-sm border-0">
                  <div class="card-body">
                    <div class="row no-gutters h-100">
                      <img
                        src="../assets/img/orange.png"
                        alt=""
                        class="small-slide-right"
                      />
                      <div class="col-8">
                        <button class="btn btn-sm btn-link p-0">
                          <i class="material-icons md-18">favorite_outline</i>
                        </button>
                        <a
                          href="all-products.html"
                          class="text-dark mb-1 mt-2 h6 d-block"
                          >Citrus
                        </a>
                        <p class="text-secondary small">
                          Oranges, Grapefruit, Mandarins
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </swiper-slide>
              <!-- <div class="swiper-slide"> -->
              <swiper-slide class="swiper-slide">
                <div class="card shadow-sm border-0">
                  <div class="card-body">
                    <div class="row no-gutters h-100">
                      <img
                        src="../assets/img/banana-small.png"
                        alt=""
                        class="small-slide-right"
                      />
                      <div class="col-8">
                        <button class="btn btn-sm btn-link p-0">
                          <i class="material-icons md-18">favorite</i>
                        </button>
                        <a
                          href="all-products.html"
                          class="text-dark mb-1 mt-2 h6 d-block"
                          >Tropical
                        </a>
                        <p class="text-secondary small">Bananas, Mangoes</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </swiper-slide>
              <!-- <div class="swiper-slide"> -->
              <swiper-slide class="swiper-slide">
                <div class="card shadow-sm border-0">
                  <div class="card-body">
                    <div class="row no-gutters h-100">
                      <img
                        src="../assets/img/grapes.png"
                        alt=""
                        class="small-slide-right"
                      />
                      <div class="col-9">
                        <button class="btn btn-sm btn-link p-0">
                          <i class="material-icons md-18">favorite_outline</i>
                        </button>
                        <a
                          href="all-products.html"
                          class="text-dark mb-1 mt-2 h6 d-block"
                          >Berries</a
                        >
                        <p class="text-secondary small">
                          Strawberries, Raspberries, Blueberries
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </swiper-slide>
            </swiper>
            <!-- </div> -->
          </div>
        </div>

        <h6 class="subtitle">
          Produk
          <a href="all-products.html" class="float-right small">View All</a>
        </h6>
        <div class="row">
          <div class="col-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card shadow-sm border-0 mb-4">
              <div class="card-body">
                <button class="btn btn-sm btn-link p-0">
                  <i class="material-icons md-18">favorite_outline</i>
                </button>
                <div class="badge badge-success float-right mt-1">10% off</div>
                <figure class="product-image">
                  <img src="../assets/img/apple.png" alt="" class="" />
                </figure>
                <router-link
                  :to="{
                    name: 'produk-show',
                    params: { id_produk: 1 },
                  }"
                  class="text-dark mb-1 mt-2 h6 d-block"
                  >Red Apple
                </router-link>
                <p class="text-secondary small mb-2">Imported from Simla</p>
                <h5 class="text-success font-weight-normal mb-0">
                  Rp. 30<sup>.000</sup>
                </h5>
                <p class="text-secondary small text-mute mb-0">1.0 kg</p>
                <router-link
                  :to="{
                    name: 'cart',
                    params: { id_produk: 1 },
                  }"
                  class="
                    btn btn-default
                    button-rounded-36
                    shadow-sm
                    float-bottom-right
                  "
                >
                  <i class="material-icons md-18">shopping_cart</i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card shadow-sm border-0 mb-4">
              <div class="card-body">
                <button class="btn btn-sm btn-link p-0">
                  <i class="material-icons md-18">favorite_outline</i>
                </button>
                <div class="badge badge-success float-right mt-1">10% off</div>
                <figure class="product-image">
                  <img src="../assets/img/grapes2.png" alt="" class="" />
                </figure>
                <router-link
                  :to="{
                    name: 'produk-show',
                    params: { id_produk: 1 },
                  }"
                  class="text-dark mb-1 mt-2 h6 d-block"
                  >Green Grapes</router-link
                >
                <p class="text-secondary small mb-2">Chilled fresh</p>
                <h5 class="text-success font-weight-normal mb-0">
                  Rp. 30<sup>.000</sup>
                </h5>
                <p class="text-secondary small text-mute mb-0">1.0 kg</p>
                <router-link
                  :to="{
                    name: 'cart',
                    params: { id_produk: 1 },
                  }"
                  class="
                    btn btn-default
                    button-rounded-36
                    shadow-sm
                    float-bottom-right
                  "
                >
                  <i class="material-icons md-18">shopping_cart</i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card shadow-sm border-0 mb-4">
              <div class="card-body">
                <button class="btn btn-sm btn-link p-0">
                  <i class="material-icons md-18">favorite_outline</i>
                </button>
                <div class="badge badge-primary float-right mt-1">10% off</div>
                <figure class="product-image">
                  <img src="../assets/img/orange-2.png" alt="" class="" />
                </figure>
                <router-link
                  :to="{
                    name: 'produk-show',
                    params: { id_produk: 1 },
                  }"
                  class="text-dark mb-1 mt-2 h6 d-block"
                  >Orange</router-link
                >
                <p class="text-secondary small mb-2">Sweet and Juicy</p>
                <h5 class="text-success font-weight-normal mb-0">
                  Rp. 30<sup>.000</sup>
                </h5>
                <p class="text-secondary small text-mute mb-0">1.0 kg</p>
                <router-link
                  :to="{
                    name: 'cart',
                    params: { id_produk: 1 },
                  }"
                  class="
                    btn btn-default
                    button-rounded-36
                    shadow-sm
                    float-bottom-right
                  "
                >
                  <i class="material-icons md-18">shopping_cart</i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card shadow-sm border-0 mb-4">
              <div class="card-body">
                <button class="btn btn-sm btn-link p-0">
                  <i class="material-icons md-18">favorite_outline</i>
                </button>
                <div class="badge badge-info float-right mt-1">50% off</div>
                <figure class="product-image">
                  <img src="../assets/img/orange-small.png" alt="" class="" />
                </figure>
                <router-link
                  :to="{
                    name: 'produk-show',
                    params: { id_produk: 1 },
                  }"
                  class="text-dark mb-1 mt-2 h6 d-block"
                  >Lemon</router-link
                >
                <p class="text-secondary small mb-2">Most Demanded</p>
                <h5 class="text-success font-weight-normal mb-0">
                  Rp. 30<sup>.000</sup>
                </h5>
                <p class="text-secondary small text-mute mb-0">1.0 kg</p>
                <router-link
                  :to="{
                    name: 'cart',
                    params: { id_produk: 1 },
                  }"
                  class="
                    btn btn-default
                    button-rounded-36
                    shadow-sm
                    float-bottom-right
                  "
                >
                  <i class="material-icons md-18">shopping_cart</i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card shadow-sm border-0 mb-4">
              <div class="card-body">
                <button class="btn btn-sm btn-link p-0">
                  <i class="material-icons md-18">favorite_outline</i>
                </button>
                <div class="badge badge-success float-right mt-1">10% off</div>
                <figure class="product-image">
                  <img src="../assets/img/apple.png" alt="" class="" />
                </figure>
                <router-link
                  :to="{
                    name: 'produk-show',
                    params: { id_produk: 1 },
                  }"
                  class="text-dark mb-1 mt-2 h6 d-block"
                  >Red Apple
                </router-link>
                <p class="text-secondary small mb-2">Imported from Simla</p>
                <h5 class="text-success font-weight-normal mb-0">
                  Rp. 30<sup>.000</sup>
                </h5>
                <p class="text-secondary small text-mute mb-0">1.0 kg</p>
                <router-link
                  :to="{
                    name: 'cart',
                    params: { id_produk: 1 },
                  }"
                  class="
                    btn btn-default
                    button-rounded-36
                    shadow-sm
                    float-bottom-right
                  "
                >
                  <i class="material-icons md-18">shopping_cart</i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card shadow-sm border-0 mb-4">
              <div class="card-body">
                <button class="btn btn-sm btn-link p-0">
                  <i class="material-icons md-18">favorite_outline</i>
                </button>
                <div class="badge badge-success float-right mt-1">10% off</div>
                <figure class="product-image">
                  <img src="../assets/img/grapes2.png" alt="" class="" />
                </figure>
                <router-link
                  :to="{
                    name: 'produk-show',
                    params: { id_produk: 1 },
                  }"
                  class="text-dark mb-1 mt-2 h6 d-block"
                  >Green Grapes</router-link
                >
                <p class="text-secondary small mb-2">Chilled fresh</p>
                <h5 class="text-success font-weight-normal mb-0">
                  Rp. 40<sup>.000</sup>
                </h5>
                <p class="text-secondary small text-mute mb-0">1.0 kg</p>
                <router-link
                  :to="{
                    name: 'cart',
                    params: { id_produk: 1 },
                  }"
                  class="
                    btn btn-default
                    button-rounded-36
                    shadow-sm
                    float-bottom-right
                  "
                >
                  <i class="material-icons md-18">shopping_cart</i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid bg-warning text-white my-3">
        <div class="row">
          <div class="container">
            <div class="row py-4">
              <div class="col">
                <h1 class="text-uppercase mb-3">20% OFF Season Sale</h1>
                <p class="mb-3">
                  Use Coupan Code<br /><span class="text-dark">DFR0020</span>
                </p>
              </div>
              <div class="col-5 col-md-3 col-lg-2 col-xl-2">
                <img
                  src="../assets/img/banana.png"
                  alt=""
                  class="mw-100 mt-3"
                />
              </div>
              <div class="w-100"></div>
              <div class="col">
                <p>Get the all new fresh bananas at very low price</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <div class="no-gutters">
          <div class="col-auto mx-auto">
            <div class="row no-gutters justify-content-center">
              <div class="col-auto">
                <router-link to="/" class="btn btn-link-default active">
                  <i class="material-icons">store_mall_directory</i>
                </router-link>
              </div>
              <div class="col-auto">
                <a href="statistics.html" class="btn btn-link-default">
                  <i class="material-icons">insert_chart_outline</i>
                </a>
              </div>
              <div class="col-auto">
                <a href="cart.html" class="btn btn-default shadow centerbutton">
                  <i class="material-icons">local_mall</i>
                </a>
              </div>
              <div class="col-auto">
                <router-link to="" class="btn btn-link-default">
                  <i class="material-icons">favorite</i>
                </router-link>
              </div>
              <div class="col-auto">
                <router-link to="/profile" class="btn btn-link-default">
                  <i class="material-icons">account_circle</i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- notification -->
    <div class="notification bg-white shadow border-primary">
      <div class="row">
        <div class="col-auto align-self-center pr-0">
          <i class="material-icons text-primary md-36">fullscreen</i>
        </div>
        <div class="col">
          <h6>Viewing in Phone?</h6>
          <p class="mb-0 text-secondary">
            Double tap to enter into fullscreen mode for each page.
          </p>
        </div>
        <div class="col-auto align-self-center pl-0">
          <button class="btn btn-link closenotification">
            <i class="material-icons text-secondary text-mute md-18">close</i>
          </button>
        </div>
      </div>
    </div>
    <!-- notification ends -->
  </div>
</template>

<script>
import $ from "jquery"; //import jQuery

import { Navigation, Pagination } from "swiper";

import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";

// Import Swiper styles
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);

export default {
  // name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {};
  },
  created() {
    // this.$isLoading(true);
    this.LoadScreen();
    this.OnLoad();
    this.Notification();
    this.FloadLabel();
    this.MenuButton();
    this.Wrapper();
    this.FilterMenuOpen();
    this.FilterButton();
    this.Background();
    setTimeout(() => {
      // this.$isLoading(false);
    }, 2000);
  },
  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
    DragScroll() {
      $(document).ready(function () {
        $.dragScroll = function (options) {
          var settings = $.extend(
            {
              scrollVertical: true,
              scrollHorizontal: true,
              cursor: null,
            },
            options
          );

          var clicked = false,
            clickY,
            clickX;

          var getCursor = function () {
            if (settings.cursor) return settings.cursor;
            if (settings.scrollVertical && settings.scrollHorizontal)
              return "url(../assets/img/touch.png), move";
            if (settings.scrollVertical) return "row-resize";
            if (settings.scrollHorizontal) return "col-resize";
          };

          var updateScrollPos = function (e, el) {
            $("html").css("cursor", getCursor());
            var $el = $(el);
            settings.scrollVertical &&
              $el.scrollTop($el.scrollTop() + (clickY - e.pageY));
            settings.scrollHorizontal &&
              $el.scrollLeft($el.scrollLeft() + (clickX - e.pageX));
          };

          $(document).on({
            mousemove: function (e) {
              clicked && updateScrollPos(e, this);
            },
            mousedown: function (e) {
              clicked = true;
              clickY = e.pageY;
              clickX = e.pageX;
            },
            mouseup: function () {
              clicked = false;
              $("html").css(
                "cursor",
                "url(../assets/img/logo-cursor.png), auto"
              );
            },
          });
        };
      });
    },

    Background() {
      $(document).ready(function () {
        $(".background").each(function () {
          // (function ($) {
          var imagewrap = $(this);
          var imagecurrent = $(this).find("img").attr("src");
          imagewrap.css("background-image", 'url("' + imagecurrent + '")');
          $(this).find("img").remove();
          // });
        });
      });
    },
    FilterButton() {
      $(document).ready(function () {
        $(".filter-btn").on("click", function () {
          // (function ($) {
          if ($("body").hasClass("filtermenu-open") == true) {
            $("body").removeClass("filtermenu-open");
            $(this).find("i").html("filter_list");
          } else {
            $("body").addClass("filtermenu-open");
            $(this).find("i").html("close");
          }
          // });
        });
      });
    },

    FilterMenuOpen() {
      $(document).ready(function () {
        if ($("body").hasClass("filtermenu-open") == true) {
          $(".filter-btn").find("i").html("close");
        }
      });
    },
    Wrapper() {
      $(document).ready(function () {
        $(".wrapper").on("click", function () {
          // (function ($) {
          if ($("body").hasClass("sidemenu-open") == true) {
            $("body, html").removeClass("sidemenu-open");
            setTimeout(function () {
              $("body, html").removeClass("menuactive");
            }, 500);
          }
          // });
        });
      });
    },

    MenuButton() {
      $(document).ready(function () {
        $(".menu-btn").on("click", function (e) {
          // (function ($) {
          e.stopPropagation();
          if ($("body").hasClass("sidemenu-open") == true) {
            $("body, html").removeClass("sidemenu-open");
            setTimeout(function () {
              $("body, html").removeClass("menuactive");
            }, 500);
          } else {
            $("body, html").addClass("sidemenu-open menuactive");
          }
          // });
        });
      });
    },

    FloadLabel() {
      $(document).ready(function () {
        $(".float-label .form-control").on("blur", function () {
          // (function ($) {
          if ($(this).val() || $(this).val().length != 0) {
            $(this).closest(".float-label").addClass("active");
          } else {
            $(this).closest(".float-label").removeClass("active");
          }
          // });
        });
      });
    },

    LoadScreen() {
      $(document).ready(function () {
        $(".loader-screen").fadeOut("slow");

        /* header active on scroll more than 50 px*/
        if ($(this).scrollTop() >= 30) {
          $(".header").addClass("active");
        } else {
          $(".header").removeClass("active");
        }

        $(window).on("scroll", function () {
          /* header active on scroll more than 50 px*/
          if ($(this).scrollTop() >= 30) {
            $(".header").addClass("active");
          } else {
            $(".header").removeClass("active");
          }
        });
      });
    },

    Notification() {
      $(document).ready(function () {
        /* notification view and hide */
        setTimeout(function () {
          $(".notification").addClass("active");
          setTimeout(function () {
            $(".notification").removeClass("active");
          }, 3500);
        }, 500);
        $(".closenotification").on("click", function () {
          $(this).closest(".notification").removeClass("active");
        });
      });
    },

    OnLoad() {
      $(document).ready(function () {
        $(window).on("load", function () {
          // (function ($) {
          /* swiper slider carousel */
          var swiper = new Swiper(".small-slide", {
            slidesPerView: "auto",
            spaceBetween: 0,
          });

          var swiper = new Swiper(".news-slide", {
            slidesPerView: 5,
            spaceBetween: 0,
            pagination: {
              el: ".swiper-pagination",
            },
            breakpoints: {
              1024: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              320: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
            },
          });
        });
        // });
      });
    },
  },
};
</script>
